import { useMutation } from '@apollo/client';
import {
  getErrorMessages,
  getQueryName,
  ModalControl,
  SecurityContext,
} from '@lib';
import clsx from 'clsx';
import { Link } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';

import {
  CompletedShippingDocument,
  CompleteShippingFragment,
  GetShippingsDocument,
  MutationDeleteShippingArgs,
  MutationUpdateShippingArgs,
} from '@redsleeve/oilynx-domain';
import { UserStatus } from '@redsleeve/oilynx-domain/build/generated/typed-document-nodes';

import CategoryDotsMenu from '@components/CategoryDotsMenu';
import ShippingForm from '@components/ShippingForm';
import Modal from '@components/modal/Modal';

import { COUNTRY_MAP, COUNTRY_UNDEFINED } from '@data/countries';

import { formatDate, formatNumber } from '@lib/format';
import { useCategories } from '@lib/hooks/useOrderedCategories';
import {
  DEFAULT_MAP_LOCATION,
  mapboxGl,
  MapboxGlMap,
  MapboxGlMarker,
} from '@lib/mapbox';

import './ShippingInfo.css';

type ShippingInfoProps = {
  shippingModal: ModalControl<CompleteShippingFragment>;
  editShipping: (args: {
    variables: MutationUpdateShippingArgs;
  }) => Promise<unknown>;
  editModal: ModalControl<CompleteShippingFragment>;
  deleteModal: ModalControl;
  deleteShipping: (args: {
    variables: MutationDeleteShippingArgs;
  }) => Promise<unknown>;
};

const ShippingInfo: FC<ShippingInfoProps> = ({
  shippingModal,
  editModal,
  editShipping,
  deleteShipping,
  deleteModal,
}) => {
  const mapContainer = useRef();
  const mapRef = useRef<MapboxGlMap>();
  const markerRef = useRef<MapboxGlMarker>();
  const [category, setCategoryName] = useState('');
  const { orderedCategories: categories } = useCategories();
  const { currentUser, isSuperAdmin } = useContext(SecurityContext);
  const [isShippingCompleted, setTsShippingCompleted] = useState(
    shippingModal.data.completed
  );
  const [completeShipping] = useMutation(CompletedShippingDocument, {
    refetchQueries: [getQueryName(GetShippingsDocument)],
  });

  const completeShippingHandler = async () => {
    const toastId = toast.loading('Creating shipping...');
    try {
      await completeShipping({
        variables: {
          shippingId: shippingModal.data.id,
          status: !isShippingCompleted,
        },
      });

      toast.success(
        `Shipping marked as ${
          isShippingCompleted ? 'incomplete' : 'complete'
        }!`,
        { id: toastId }
      );
      setTsShippingCompleted(!isShippingCompleted);
    } catch (ex) {
      const errorMessages = getErrorMessages(ex);
      toast.error(
        errorMessages?.join('\n') ??
          `Failed to mark shipping as ${
            isShippingCompleted ? 'incomplete' : 'complete'
          }.`,
        {
          id: toastId,
          duration: 10 * 1000,
        }
      );
      if (!errorMessages)
        console.error('[ShippingInfo] completeShippingHandler', ex);
    }
  };

  useEffect(() => {
    mapRef.current = mapContainer.current
      ? new mapboxGl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/dark-v10',
          center: [
            shippingModal.data.position.location.coordinates[0],
            shippingModal.data.position.location.coordinates[1],
          ],
          zoom: DEFAULT_MAP_LOCATION.zoom,
        })
      : null;
    return () => mapRef.current?.remove();
  }, [mapRef]);

  useEffect(() => {
    markerRef.current =
      mapRef.current &&
      new mapboxGl.Marker()
        .setLngLat([
          shippingModal.data.position?.location.coordinates[0],
          shippingModal.data.position?.location.coordinates[1],
        ])
        .addTo(mapRef.current);
  }, [mapRef.current]);

  useEffect(() => {
    const category = categories.find(
      (category) => category.id === shippingModal.data.lastCargoCategoryId
    );
    category && setCategoryName(category.name);
  }, [shippingModal.data, categories]);

  const contactInfoCountry =
    COUNTRY_MAP.get(shippingModal.data.contactInfo?.country) ??
    COUNTRY_UNDEFINED;

  const handleDeleteShipping: typeof deleteShipping = async (args) => {
    await deleteShipping(args);
    shippingModal.close();
  };

  const rowClassName = 'table-row lg:block';
  const headerCellClassName = `
    table-cell lg:block w-1/3 lg:w-auto 
    text-sm font-emp lg:font-body opacity-40 lg:opacity-60 
    mb-0 py-2 pr-4 lg:p-0
  `;
  const valueCellClassName = `
    table-cell lg:block w-2/3 lg:w-auto 
    font-emp text-sm lg:text-base
  `;

  return (
    <>
      <div className="ShippingInfo-wrapper">
        <Modal
          control={shippingModal}
          contentClassName="ShippingInfo-modal"
          size=""
        >
          <div className="flex items-center justify-center lg:justify-start pb-10 lg:-mt-2">
            <span className="h-7 w-7 bg-background-light rounded-full flex justify-center items-center">
              <svg
                className={clsx(
                  'h-3 w-3',

                  'text-shipping-sell' // TODO
                )}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="5 5 10 10"
                fill="currentColor"
              >
                {/*'text-shipping-sell' // TODO*/}
                <path
                  fillRule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <p className="pl-4 mb-0 font-emp font-bold text-xl lg:text-4xl">
              {shippingModal.data.name}
            </p>
            {shippingModal.data.createdByMe && (
              <svg
                className="h-4 ml-3 text-shipping-mine"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            <span className="absolute top-4 right-4 lg:right-12">
              {(shippingModal.data.createdByMe || isSuperAdmin) && (
                <CategoryDotsMenu
                  deleteButton={'Delete'}
                  editButton={'Edit'}
                  deleteHandler={() => deleteModal.open(shippingModal.data)}
                  editHandler={() => editModal.open(shippingModal.data)}
                  completeHandler={completeShippingHandler}
                  markAsCompleted={true}
                  isCompleted={
                    !isShippingCompleted
                      ? 'Mark as complete'
                      : 'Mark as incomplete'
                  }
                  showOwnerEdit={false}
                />
              )}
            </span>
          </div>

          <div className="flex flex-wrap w-full">
            {!currentUser ? (
              <div className="m-auto h-64 flex flex-col items-center justify-center bg-background-light p-4 rounded ">
                <div className="mb-8 font-emp font-bold text-base lg:text-2xl">
                  Please Login to view Shipping details
                </div>
                <Link
                  to="/login/"
                  className=" flex justify-center items-center no-underline	w-20 h-12 bg-background-dark hover:bg-background-avg rounded "
                >
                  Login
                </Link>
              </div>
            ) : (
              <>
                <div className="w-full lg:w-3/5">
                  <div>
                    <p className="font-emp font-bold text-base lg:text-2xl mb-2 lg:mb-5 pb-1">
                      General Information
                    </p>

                    <div className="table table-fixed lg:flex flex-wrap">
                      <div className={clsx(rowClassName, 'lg:w-2/4')}>
                        <p className={clsx(headerCellClassName)}>Size</p>
                        <p className={clsx(valueCellClassName)}>
                          {formatNumber(shippingModal.data.size)}
                          {' cbm'}
                        </p>
                      </div>

                      <div className={clsx(rowClassName, 'lg:w-2/4')}>
                        <p className={clsx(headerCellClassName)}>Built</p>
                        <p className={clsx(valueCellClassName)}>
                          {shippingModal.data.built}
                        </p>
                      </div>

                      <div className={rowClassName}>
                        <p className={clsx(headerCellClassName)}>
                          Additional Information
                        </p>
                        <p className={clsx(valueCellClassName)}>
                          {shippingModal.data.additionalInfo || 'N/A'}
                        </p>
                      </div>
                    </div>
                    <hr className="bg-interaction-light w-full opacity-10 lg:opacity-100 mb-3.5" />
                  </div>

                  <div>
                    {/*<p className="font-emp font-bold text-base lg:text-2xl mb-2 lg:mb-5 pb-1">*/}
                    {/*  Pricing*/}
                    {/*</p>*/}

                    <div className="table table-fixed lg:flex flex-wrap">
                      <div className={clsx(rowClassName, 'lg:w-2/4')}>
                        <p className={headerCellClassName}>Type</p>
                        <p className={valueCellClassName}>
                          {shippingModal.data.type}
                        </p>
                      </div>
                      <div className={clsx(rowClassName, 'lg:w-2/4')}>
                        <p className={headerCellClassName}>Last Cargo</p>
                        <p className={valueCellClassName}>{category}</p>
                      </div>
                    </div>

                    <hr className="bg-interaction-light w-full opacity-10 lg:opacity-100 mb-3.5" />
                  </div>

                  <div>
                    <p className="font-emp font-bold text-base lg:text-2xl mb-2 lg:mb-5 pb-1">
                      Open Information
                    </p>
                    <div className="table table-fixed lg:flex flex-wrap">
                      <div className={clsx(rowClassName, 'lg:w-2/4')}>
                        <p className={headerCellClassName}>Open Window</p>
                        <div className={valueCellClassName}>
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="hidden lg:block h-4 w-4 text-shipping-mine"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <p className="lg:ml-2 mb-0">
                              {formatDate(shippingModal.data.openWindowStart)}
                              {!!shippingModal.data.openWindowEnd && (
                                <>
                                  {' - '}
                                  {formatDate(shippingModal.data.openWindowEnd)}
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={clsx(rowClassName, 'lg:w-2/4')}>
                        <p className={headerCellClassName}>Position</p>
                        <p className={valueCellClassName}>
                          {shippingModal.data.position.label}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="lg:pl-10 flex flex-col-reverse lg:flex-col w-full lg:w-2/5 -mt-7">
                  <div
                    className={`ContactInformation-wrapper
                bg-background-light 
                -mx-4 mt-4 -mb-4 lg:m-0 p-4 lg:px-8 lg:pt-7 lg:pb-0 
                lg:rounded
              `}
                  >
                    <p className="font-emp font-bold text-base lg:text-2xl mb-2 lg:mb-5 pb-1">
                      Contact Information
                    </p>

                    {!currentUser ? (
                      <div className="h-64 flex items-center justify-center">
                        <Link
                          to="/login/"
                          className=" flex justify-center items-center no-underline	w-20 h-12 bg-background-dark hover:bg-background-avg rounded "
                        >
                          Login
                        </Link>
                      </div>
                    ) : currentUser.status === UserStatus.Disabled ? (
                      <div className="h-64">Your account is disabled</div>
                    ) : currentUser.status ===
                      UserStatus.AwaitingConfirmation ? (
                      <div className="h-64">
                        Your account is awaiting for confirmation
                      </div>
                    ) : !!shippingModal.data.contactInfo ? (
                      <div className="table table-fixed">
                        <div className={rowClassName}>
                          <p className={clsx(headerCellClassName)}>
                            {shippingModal.data.type === 'BUY'
                              ? 'Buyer'
                              : 'Seller'}
                          </p>
                          <p className={clsx(valueCellClassName)}>
                            {shippingModal.data.contactInfo.name}
                          </p>
                        </div>
                        <div className={rowClassName}>
                          <p className={clsx(headerCellClassName)}>
                            Phone Number
                          </p>
                          <div className={clsx(valueCellClassName)}>
                            <div className="flex items-center">
                              <img
                                className="w-5 h-5 mb-0"
                                src={contactInfoCountry.flag}
                                alt={`${contactInfoCountry.name} flag`}
                              />
                              <span className="pl-2">
                                {shippingModal.data.contactInfo.phoneNumber}
                              </span>
                              <span className="pl-5">W</span>
                              <span className="">T</span>
                            </div>
                          </div>
                        </div>
                        <div className={rowClassName}>
                          <p className={clsx(headerCellClassName)}>Email</p>
                          <p className={clsx(valueCellClassName)}>
                            {shippingModal.data.contactInfo.email}
                          </p>
                        </div>
                        <div className={rowClassName}>
                          <p className={clsx(headerCellClassName)}>
                            Company Details
                          </p>
                          <p className={clsx(valueCellClassName)}>
                            {shippingModal.data.contactInfo.companyName}
                            {', '}
                            {shippingModal.data.contactInfo.companyAddress}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="h-64">
                        You are not subscribed to this category
                      </div>
                    )}
                  </div>
                  <div className="pt-10">
                    <div
                      ref={mapContainer}
                      className={`block h-36 lg:h-72 w-full rounded`}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal>
      </div>
      <div className="CreateShipping-wrapper">
        <ShippingForm
          editModal={editModal}
          editShipping={editShipping}
          deleteModal={deleteModal}
          deleteShipping={handleDeleteShipping}
        />
      </div>
    </>
  );
};
export default ShippingInfo;
