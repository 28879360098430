import { useMutation, useQuery } from '@apollo/client';
import Layout from '@layouts';
import { getQueryName, SecurityContext, useIsMobile, useModal } from '@lib';
import clsx from 'clsx';
import { PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import {
  CompleteShippingFragment,
  DeleteShippingDocument,
  GetShippingFilterDataDocument,
  GetShippingsDocument,
  Shipping,
  UpdateShippingDocument,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import OilMap from '@components/Map';
import ShippingInfo from '@components/ShippingInfo/ShippingInfo';
import ShippingsTable, { TableHeader } from '@components/ShippingTable';
import ClearFilters from '@components/filter/ClearFilters';
import DeliveryWindowFilter from '@components/filter/DeliveryWindowFilter';
import { FiltersContextProvider } from '@components/filter/FiltersContext';
import LastCargoFilter from '@components/filter/shipping/LastCargoFilter';
import MobileShippingFilter from '@components/filter/shipping/MobileShippingFilter';
import NumberFilter from '@components/filter/shipping/NumberFilter';
import TypeFilter from '@components/filter/shipping/TypeFilter';

import { useCategories } from '@lib/hooks/useOrderedCategories';

import CategoryDotsMenu from '../../components/CategoryDotsMenu';
import ShippingForm from '../../components/ShippingForm';
import { TradesAndShippingsContext } from '../TradesAndShippingsContext';
import CreateShipping from './CreateShipping';
import './CreateShipping.css';
import './index.css';

type ShippingsPageProps = PageProps & {};
const ShippingsPage: FC<ShippingsPageProps> = ({ location, navigate }) => {
  const {
    shippingSort: sort,
    setShippingSort: setSort,
    shippingsFilterFormik: filterFormik,
    getShippingsQuery,
  } = useContext(TradesAndShippingsContext);

  const [mobileMode, setMobileMode] = useState<'map' | 'table'>('map');
  const isMobile = useIsMobile();
  useEffect(() => {
    if (isMobile && mobileMode == 'map') {
      window.scrollTo({ top: 0 });
    }
  }, [isMobile, mobileMode]);

  const { currentUser } = useContext(SecurityContext);

  const shippingFilterDataQuery = useQuery(GetShippingFilterDataDocument);

  const viewShippingModal = useModal<Shipping>();

  // open modal for each highlight once
  useEffect(() => {
    const selectedShippingOnRender = (
      getShippingsQuery.data?.getShippings ?? []
    ).find((it) => location.hash === `#${it.id}`);
    if (selectedShippingOnRender) {
      viewShippingModal.open(selectedShippingOnRender as any);
      navigate('/shipping/', { replace: true });
    }
  }, [getShippingsQuery.data?.getShippings, location.hash]);

  const editModal = useModal<CompleteShippingFragment>();
  const deleteModal = useModal<CompleteShippingFragment>();
  const [editShipping] = useMutation(UpdateShippingDocument, {
    refetchQueries: [
      getQueryName(GetShippingsDocument),
      getQueryName(GetShippingFilterDataDocument),
    ],
  });
  const [deleteShipping] = useMutation(DeleteShippingDocument, {
    refetchQueries: [
      getQueryName(GetShippingsDocument),
      getQueryName(GetShippingFilterDataDocument),
    ],
  });

  const { getCategory } = useCategories();
  const getCategoryRef = useRef(getCategory);
  useUpdateRefIfShallowNew(getCategoryRef, getCategory);

  return (
    <Layout
      url="/shipping"
      title="Shipping Positions"
      hideFooter={isMobile && mobileMode === 'map'}
    >
      <FiltersContextProvider>
        <h1
          className={clsx(
            `font-emp font-bold text-2xl text-center w-full mb-0
          mt-3 lg:mt-6
          `,
            mobileMode === 'map' && 'hidden lg:block'
          )}
        >
          Shipping
        </h1>
        <p className="hidden lg:block w-full text-center font-emp text-xs opacity-70">
          Oilynx is your platform to place, monitor, and discover new trading
          opportunities in your industry. Start right here!
        </p>

        <CreateShipping />
        <OilMap mobileMode={mobileMode}>
          <LastCargoFilter formik={filterFormik} name="categories" />
          <NumberFilter
            formik={filterFormik}
            nameLeft="minSize"
            nameRight="maxSize"
            label="Size"
            unit="cbm"
            histogram={
              shippingFilterDataQuery.data?.getShippingFilterData
                ?.sizeHistogram ?? []
            }
          />
          <NumberFilter
            formik={filterFormik}
            nameLeft="minBuilt"
            nameRight="maxBuilt"
            label="Built"
            unit=""
            histogram={
              shippingFilterDataQuery.data?.getShippingFilterData
                ?.builtHistogram ?? []
            }
          />
          <TypeFilter formik={filterFormik} name="types" />
          <DeliveryWindowFilter
            formik={filterFormik}
            label="Open Window"
            nameStart="openWindowStart"
            nameEnd="openWindowEnd"
          />
          <ClearFilters />
        </OilMap>

        <MobileShippingFilter
          mode={mobileMode}
          filterFormik={filterFormik}
          sizeHistogram={
            shippingFilterDataQuery.data?.getShippingFilterData
              ?.sizeHistogram ?? []
          }
          builtHistogram={
            shippingFilterDataQuery.data?.getShippingFilterData
              ?.builtHistogram ?? []
          }
          sort={sort}
          setSort={setSort}
        />

        <div className={clsx(mobileMode === 'map' && `hidden lg:block`)}>
          <ShippingsTable
            shippings={getShippingsQuery.data?.getShippings ?? []}
            sort={sort}
            setSort={setSort}
            viewModal={viewShippingModal}
            highlightRow={location.hash}
            lastColumnHeader={
              currentUser && (
                <TableHeader
                  className="flex-row-reverse has-tooltip"
                  label={
                    <svg
                      className="h-4 text-shipping-mine"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path
                        fillRule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                >
                  <span className="tooltip text-white transform translate-x-full -mr-4">
                    My shippings
                  </span>
                </TableHeader>
              )
            }
            renderLastColumnCell={(shipping) =>
              currentUser && (
                <td
                  className="flex flex-row-reverse"
                  onClick={(e) => shipping.createdByMe && e.stopPropagation()}
                >
                  {shipping.createdByMe && (
                    <CategoryDotsMenu
                      deleteButton={'Delete'}
                      editButton={'Edit'}
                      showOwnerEdit={true}
                      deleteHandler={() => deleteModal.open(shipping)}
                      editHandler={() => editModal.open(shipping)}
                    />
                  )}
                </td>
              )
            }
          />
        </div>
        <Button
          className="lg:hidden fixed bottom-10 w-52 p-2 rounded-full left-1/2 transform -translate-x-1/2"
          variant="outlined-light"
          onClick={() =>
            setMobileMode((mode) => (mode === 'map' ? 'table' : 'map'))
          }
        >
          {mobileMode === 'map' ? 'Table' : 'Map'}
        </Button>
        <div className="CreateShipping-wrapper">
          <ShippingForm
            editModal={editModal}
            deleteModal={deleteModal}
            editShipping={editShipping}
            deleteShipping={deleteShipping}
          />
        </div>
        {viewShippingModal.data && (
          <ShippingInfo
            editShipping={editShipping}
            editModal={editModal}
            deleteShipping={deleteShipping}
            deleteModal={deleteModal}
            shippingModal={viewShippingModal}
          />
        )}
      </FiltersContextProvider>
    </Layout>
  );
};

export default ShippingsPage;
