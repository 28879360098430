import {
  getErrorMessages,
  getInvalidFields,
  mapInvalidFieldInvalidFieldNames,
  ModalControl,
} from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';
import * as Yup from 'yup';

import {
  Category,
  CompleteShippingFragment,
  MutationUpdateShippingArgs,
  TradeLocation,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import CommonFields, {
  createCommonValidationSchema,
} from '@components/ShippingForm/CommonFields';
import ContactInfoFields from '@components/TradeForm/ContactInfoFields';
import { SelectInputValue } from '@components/form/SelectInput';
import Modal from '@components/modal/Modal';

import {
  COUNTRY_MAP,
  COUNTRY_UNDEFINED,
  countryValues,
  mapCountryToSelectValue,
} from '@data/countries';
import { shippingTypeValues } from '@data/shippingTypeValues';

type UpdateModalProps = {
  categoryValues: SelectInputValue[];

  editShipping: (args: {
    variables: MutationUpdateShippingArgs;
  }) => Promise<unknown>;
  editModal: ModalControl<CompleteShippingFragment>;
};

const UpdateModal: FC<UpdateModalProps> = ({
  editModal,
  editShipping,
  categoryValues,
}) => {
  const updateFormikInitialValues = {
    name: '',
    size: '' as number | '',
    built: '' as number | '',
    type: undefined as (typeof shippingTypeValues)[0],

    lastCargoCategory: undefined as { value: Category['id'] },

    openWindowStart: undefined as Date,
    openWindowEnd: undefined as Date,

    position: undefined as TradeLocation,
    additionalInfo: '',

    contactInfoName: '',
    contactInfoCompanyName: '',
    contactInfoCompanyAddress: '',
    contactInfoCountry: undefined as (typeof countryValues)[0],
    contactInfoPhoneNumber: '',
    contactInfoEmail: '',
  };
  const updateFormik = useFormik({
    initialValues: updateFormikInitialValues,
    validationSchema: Yup.object({
      ...createCommonValidationSchema(),

      contactInfoName: Yup.string().required(
        'Please specify a name for the contact person'
      ),
      contactInfoCompanyName: Yup.string().required(
        'Please specify the company name'
      ),
      contactInfoCompanyAddress: Yup.string().required(
        'Please specify the company address'
      ),
      contactInfoPhoneNumber: Yup.string().required(
        'Please specify a contact phone number'
      ),
      contactInfoEmail: Yup.string().required('Please specify a contact email'),
    }),

    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      const toastId = toast.loading('Updating shipping...');

      try {
        await editShipping({
          variables: {
            shippingId: editModal.data.id,
            shipping: {
              name: values.name,
              size: values.size,
              built: values.built,
              type: values.type.value,

              lastCargoCategoryId: values.lastCargoCategory.value,

              openWindowStart: values.openWindowStart.getTime(),
              openWindowEnd: values.openWindowEnd?.getTime(),

              position: {
                label: values.position.label,
                location: {
                  type: values.position.location.type,
                  coordinates: values.position.location.coordinates,
                },
              },
              additionalInfo: values.additionalInfo,

              customContactInfo: {
                name: values.contactInfoName,
                companyName: values.contactInfoCompanyName,
                companyAddress: values.contactInfoCompanyAddress,
                country: values.contactInfoCountry.value.isoCode,
                phoneNumber: values.contactInfoPhoneNumber,
                email: values.contactInfoEmail,
              },
            },
          },
        });

        toast.success('Shipping updated!', { id: toastId });
        updateFormik.resetForm();
        editModal.close();
      } catch (ex) {
        const formError = mapInvalidFieldInvalidFieldNames(
          getInvalidFields(ex),
          new Map([
            ['customContactInfo.phoneNumber', 'contactInfoPhoneNumber'],
            ['customContactInfo.name', 'contactInfoName'],
            ['customContactInfo.email', 'contactInfoEmail'],
            ['customContactInfo.companyName', 'contactInfoCompanyName'],
            ['customContactInfo.companyAddress', 'contactInfoCompanyAddress'],
          ])
        );
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        toast.error(errorMessages?.join('\n') ?? 'Failed to update shipping', {
          id: toastId,
          duration: 10 * 1000,
        });
        if (!errorMessages)
          console.error('[HomePage] updateShipping onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const updateFormikRef = useRef(updateFormik);
  useUpdateRefIfShallowNew(updateFormikRef, updateFormik);

  useEffect(() => {
    if (
      updateFormik.values.contactInfoCountry &&
      updateFormikRef.current.values.contactInfoPhoneNumber &&
      updateFormikRef.current.values.contactInfoPhoneNumber.indexOf(
        updateFormik.values.contactInfoCountry.value.dialCode
      ) !== 0
    ) {
      updateFormikRef.current.setFieldValue(
        'contactInfoPhoneNumber',
        updateFormik.values.contactInfoCountry.value.dialCode
      );
    }
  }, [updateFormikRef, updateFormik.values.contactInfoCountry]);

  useEffect(() => {
    if (editModal.data) {
      const shipping = editModal.data;

      updateFormikRef.current.resetForm({
        values: {
          name: shipping.name,
          built: shipping.built,
          size: shipping.size,
          type: shippingTypeValues.find((it) => it.value === shipping.type),

          lastCargoCategory: categoryValues
            .flatMap((it) => it.options ?? [it])
            .find((it) => it.value === shipping.lastCargoCategoryId),

          openWindowStart: new Date(shipping.openWindowStart),
          openWindowEnd: shipping.openWindowEnd
            ? new Date(shipping.openWindowEnd)
            : null,
          position: shipping.position,
          additionalInfo: shipping.additionalInfo,

          contactInfoName: shipping.contactInfo.name,
          contactInfoCompanyName: shipping.contactInfo.companyName,
          contactInfoCompanyAddress: shipping.contactInfo.companyAddress,
          contactInfoCountry: mapCountryToSelectValue(
            true,
            COUNTRY_MAP.get(shipping.contactInfo.country) ?? COUNTRY_UNDEFINED
          ),
          contactInfoPhoneNumber: shipping.contactInfo.phoneNumber,
          contactInfoEmail: shipping.contactInfo.email,
        },
      });

      requestAnimationFrame(() => {
        updateFormikRef.current.setFieldValue(
          'contactInfoPhoneNumber',
          shipping.contactInfo.phoneNumber
        );
      });
    } else {
      updateFormikRef.current.resetForm({ values: updateFormikInitialValues });
    }
  }, [editModal.data, updateFormikRef]);

  return (
    <>
      <Modal
        title="Edit Shipping Position"
        contentClassName="ShippingForm-modal"
        control={editModal}
        formProps={{
          onSubmit: updateFormik.handleSubmit,
        }}
        size="sm:max-w-3xl sm:w-auto"
        actions={
          <div className="p-4 lg:p-0 w-full bg-background-avg lg:bg-transparent flex justify-between">
            <Button
              className="w-1/3 lg:w-36"
              type="reset"
              onClick={editModal.close}
            >
              Cancel
            </Button>
            <Button
              className="w-2/3 lg:w-36"
              type="submit"
              name="submit"
              variant="primary"
            >
              Update Shipping
            </Button>
          </div>
        }
      >
        <CommonFields formik={updateFormik} categoryValues={categoryValues} />

        <hr className="mt-6 bg-white opacity-10" />
        <p className="text-xl mb-0 font-emp font-light">Contact Information</p>

        <ContactInfoFields formik={updateFormik} />
      </Modal>
    </>
  );
};

export default UpdateModal;
