import { FormikControl } from '@lib';
import * as React from 'react';
import { FC } from 'react';
import * as Yup from 'yup';
import { StringSchema } from 'yup';

import DateIntervalInput, {
  DateStartPiece,
} from '@components/form/DateIntervalInput';
import GeocoderInput, { PinStartPiece } from '@components/form/GeocoderInput';
import MapInput from '@components/form/MapInput';
import SelectInput, { SelectInputValue } from '@components/form/SelectInput';
import TextInput from '@components/form/TextInput';

import { shippingTypeValues } from '@data/shippingTypeValues';

type CommonFieldsProps = {
  formik: FormikControl;
  categoryValues: SelectInputValue[];
};

const CommonFields: FC<CommonFieldsProps> = ({ formik, categoryValues }) => {
  return (
    <>
      <p className="text-xl font-emp font-light">General information</p>
      <div className="mt-4 flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6">
        <TextInput
          formik={formik}
          className="w-full lg:w-72"
          name="name"
          label="Name *"
          tooltip="The name of the vessel."
          type="text"
          placeholder="eg: Fair Lady"
        />
        <TextInput
          formik={formik}
          className="w-full lg:w-72"
          name="built"
          label="Built *"
          tooltip="The year the vessel was built in."
          type="number"
          placeholder="eg: 2010"
        />
      </div>

      <div className="mt-4 flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6">
        <TextInput
          formik={formik}
          className="w-full lg:w-72"
          name="size"
          label="Size *"
          tooltip="The capacity of the vessel."
          type="number"
          placeholder="eg: 4000"
          endPiece={'cbm'}
        />
        <SelectInput
          formik={formik}
          className="w-full lg:w-72"
          name="type"
          label="Type *"
          tooltip="The type the vessel."
          values={shippingTypeValues}
          placeholder={`eg: ${shippingTypeValues[0].label}`}
        />
      </div>

      <div className="mt-4 flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6">
        <SelectInput
          formik={formik}
          className="w-full lg:w-72"
          name="lastCargoCategory"
          values={categoryValues}
          label="Last Cargo *"
          placeholder="eg: Butane"
        />
      </div>

      <hr className="mt-6 bg-white opacity-10" />
      <p className="text-xl font-emp font-light">Delivery Information</p>
      <div className="flex mt-6 items-center flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
        <div className="flex w-full lg:w-72">
          <DateIntervalInput
            formik={formik}
            startName="openWindowStart"
            endName="openWindowEnd"
            label="Open *"
            startPiece={<DateStartPiece />}
          />
        </div>
        <div className="w-full lg:w-72">
          <GeocoderInput
            formik={formik}
            name="position"
            label="Position *"
            placeholder="eg: New York"
            startPiece={<PinStartPiece />}
          />
        </div>
      </div>

      <div className="flex mt-6 w-full h-52">
        <MapInput formik={formik} name="position" />
      </div>
      <div className="flex mt-6 w-full">
        <TextInput
          formik={formik}
          className="w-full"
          name="additionalInfo"
          label="Additional Info"
          placeholder="eg: I only accept euro as payment"
          type="textarea"
          rows={4}
        />
      </div>
    </>
  );
};

export default CommonFields;

export const createCommonValidationSchema = () => ({
  lastCargoCategory: Yup.object().required('Please specify the last cargo'),
  built: Yup.number()
    .min(0, 'Built year should be a positive number')
    .required('Please specify the built year'),
  size: Yup.number()
    .min(0, 'Size should be a positive number')
    .required('Please specify the size of the vessel'),

  type: Yup.object().required('Please select the type of the vessel'),

  openWindowStart: Yup.string().required(
    'Please specify the when the vessel is available'
  ),
  openWindowEnd: Yup.string().nullable(),
  // .when(
  //   'openWindowStart',
  //   (deliveryWindowStart: any, schema: StringSchema) => {
  //     return !!deliveryWindowStart
  //       ? schema.required('Please specify the when the vessel is available')
  //       : schema;
  //   }
  // ),

  position: Yup.object().required('Please specify the position of the vessel'),
});
