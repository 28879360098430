import { ModalControl, ShippingsTableSort, useIsMobile } from '@lib';
import clsx from 'clsx';
import * as React from 'react';
import { FC, ReactNode } from 'react';

import { CompleteShippingFragment } from '@redsleeve/oilynx-domain';

import MobileTable from '@components/ShippingTable/MobileTable';

import { formatDate, formatNumber } from '@lib/format';
import { useCategories } from '@lib/hooks/useOrderedCategories';

import './ShippingsTable.css';

type TableHeaderProps = {
  label: ReactNode;
  className?: string;
  sort?: string;
  sortASC?: string;
  sortDESC?: string;
  setSort?: React.Dispatch<React.SetStateAction<string>>;
};

export const TableHeader: FC<TableHeaderProps> = ({
  className,
  label,
  sort,
  setSort,
  sortASC,
  sortDESC,
  children,
}) => (
  <th className={'text-sm font-emp text-gray-500 border-b  border-gray-700'}>
    <div className={clsx('flex items-center', className)}>
      <div
        className={clsx('text-left', setSort && 'cursor-pointer')}
        onClick={() =>
          setSort && setSort((sort) => (sort === sortASC ? sortDESC : sortASC))
        }
      >
        {label}
      </div>
      {setSort && (
        <div
          className="flex flex-col justify-center text-xs leading-3 pl-2"
          style={{ fontSize: '11px' }}
        >
          <button
            className={clsx(sort === sortASC && 'text-shipping-mine')}
            onClick={() => setSort(sortASC)}
          >
            ▲
          </button>
          <button
            className={clsx(
              '-mt-0.5',
              sort === sortDESC && 'text-shipping-mine'
            )}
            onClick={() => setSort(sortDESC)}
          >
            ▼
          </button>
        </div>
      )}
      {children}
    </div>
  </th>
);

type ShippingsTableProps = {
  shippings: CompleteShippingFragment[];

  sort: string;
  setSort: React.Dispatch<React.SetStateAction<string>>;

  lastColumnHeader: ReactNode;
  renderLastColumnCell: (shipping: CompleteShippingFragment) => ReactNode;

  highlightRow?: string;
  viewModal: ModalControl<CompleteShippingFragment>;
};

const ShippingsTable: FC<ShippingsTableProps> = ({
  shippings,
  sort,
  setSort,
  lastColumnHeader,
  renderLastColumnCell,
  highlightRow,
  viewModal,
}) => {
  const isMobile = useIsMobile();
  const { getCategory } = useCategories();

  if (!shippings.length)
    return (
      <div className="ShippingsTable-wrapper">
        <p>No shippings match the filters.</p>
      </div>
    );

  return (
    <div className="ShippingsTable-wrapper">
      {isMobile ? (
        <MobileTable shippings={shippings} viewModal={viewModal} />
      ) : (
        <table>
          <thead>
            <tr>
              <TableHeader
                label="Name"
                setSort={setSort}
                sort={sort}
                sortASC={ShippingsTableSort.NameASC}
                sortDESC={ShippingsTableSort.NameDESC}
              />
              <TableHeader
                label="Size (cbm)"
                setSort={setSort}
                sort={sort}
                sortASC={ShippingsTableSort.SizeASC}
                sortDESC={ShippingsTableSort.SizeDESC}
              />
              <TableHeader
                label="Built"
                setSort={setSort}
                sort={sort}
                sortASC={ShippingsTableSort.BuiltASC}
                sortDESC={ShippingsTableSort.BuiltDESC}
              />
              <TableHeader
                label="Type"
                setSort={setSort}
                sort={sort}
                sortASC={ShippingsTableSort.TypeASC}
                sortDESC={ShippingsTableSort.TypeDESC}
              />
              <TableHeader label="Position" />
              <TableHeader
                label="Open"
                setSort={setSort}
                sort={sort}
                sortASC={ShippingsTableSort.OpenWindowASC}
                sortDESC={ShippingsTableSort.OpenWindowDESC}
              />
              <TableHeader
                label="Last Cargo"
                setSort={setSort}
                sort={sort}
                sortASC={ShippingsTableSort.LastCargoASC}
                sortDESC={ShippingsTableSort.LastCargoDESC}
              />
              {lastColumnHeader}
            </tr>
          </thead>
          <tbody>
            {shippings.map((shipping, index) => (
              <React.Fragment key={shipping.id}>
                {!shipping.active &&
                  (!index || shippings[index - 1].active) && (
                    <tr>
                      <td colSpan={10} className="divider">
                        <div className="flex space-x-16 w-full items-center opacity-60 mt-12">
                          <hr className="flex-1 bg-interaction-light w-full opacity-10 lg:opacity-100 h-0.5 mb-0" />
                          <div
                            className={'font-emp font-bold text-base'}
                            style={{ letterSpacing: '4px' }}
                          >
                            ARCHIVED SHIPPING POSITIONS
                          </div>
                          <hr className="flex-1 bg-interaction-light w-full opacity-10 lg:opacity-100 h-0.5 mb-0" />
                        </div>
                      </td>
                    </tr>
                  )}
                <tr
                  onClick={() => viewModal.open(shipping as any)}
                  id={shipping.id}
                  className={clsx(
                    'hover:bg-background-avg cursor-pointer',
                    highlightRow === `#${shipping.id}` && 'bg-background-avg',
                    !shipping.active && 'opacity-70'
                  )}
                >
                  <td>{shipping.name}</td>
                  <td>{formatNumber(shipping.size)} cbm</td>
                  <td> {shipping.built} </td>
                  <td> {shipping.type} </td>
                  <td>{shipping.position.label}</td>
                  <td>
                    {formatDate(shipping.openWindowStart)}
                    {!!shipping.openWindowEnd && (
                      <>
                        {' - '}
                        {formatDate(shipping.openWindowEnd)}
                      </>
                    )}
                  </td>
                  <td>
                    {getCategory(shipping.lastCargoCategoryId)?.name ?? 'Other'}
                  </td>
                  {renderLastColumnCell(shipping)}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ShippingsTable;
