import {
  getErrorMessages,
  getInvalidFields,
  mapInvalidFieldInvalidFieldNames,
  ModalControl,
} from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';
import * as Yup from 'yup';
import { StringSchema } from 'yup';

import {
  Category,
  MutationCreateShippingArgs,
  TradeLocation,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import CommonFields, {
  createCommonValidationSchema,
} from '@components/ShippingForm/CommonFields';
import ContactInfoFields from '@components/TradeForm/ContactInfoFields';
import SelectInput, { SelectInputValue } from '@components/form/SelectInput';
import Modal from '@components/modal/Modal';

import { countryValues, mapCountryToSelectValue } from '@data/countries';
import { shippingTypeValues } from '@data/shippingTypeValues';

import { useUserCountry } from '@lib/hooks/useUserCountry';

type CreateModalProps = {
  contactInfoValues: SelectInputValue[];
  categoryValues: SelectInputValue[];
  createShipping: (args: {
    variables: MutationCreateShippingArgs;
  }) => Promise<unknown>;
  createModal: ModalControl;

  siteUrl: string;
};

const CreateModal: FC<CreateModalProps> = ({
  contactInfoValues,
  categoryValues,
  createShipping,
  createModal,

  siteUrl,
}) => {
  const userCountry = useUserCountry();

  const createFormik = useFormik({
    initialValues: {
      name: '',
      size: '' as number | '',
      built: '' as number | '',
      type: undefined as (typeof shippingTypeValues)[0],

      lastCargoCategory: undefined as { value: Category['id'] },

      openWindowStart: undefined as Date,
      openWindowEnd: undefined as Date,

      position: undefined as TradeLocation,
      additionalInfo: '',

      contactInfo: undefined as { value: string | object },
      contactInfoName: '',
      contactInfoCompanyName: '',
      contactInfoCompanyAddress: '',
      contactInfoCountry: undefined as (typeof countryValues)[0],
      contactInfoPhoneNumber: '',
      contactInfoEmail: '',
    },
    validationSchema: Yup.object({
      ...createCommonValidationSchema(),

      contactInfo: Yup.object().required('Please choose a contact method'),
      contactInfoName: Yup.string().when(
        'contactInfo',
        (contactInfo: any, schema: StringSchema) => {
          return contactInfo?.value === 'OTHER'
            ? schema.required('Please specify a name for the contact person')
            : schema;
        }
      ),
      contactInfoCompanyName: Yup.string().when(
        'contactInfo',
        (contactInfo: any, schema: StringSchema) => {
          return contactInfo?.value === 'OTHER'
            ? schema.required('Please specify the company name')
            : schema;
        }
      ),
      contactInfoCompanyAddress: Yup.string().when(
        'contactInfo',
        (contactInfo: any, schema: StringSchema) => {
          return contactInfo?.value === 'OTHER'
            ? schema.required('Please specify the company address')
            : schema;
        }
      ),
      contactInfoPhoneNumber: Yup.string().when(
        'contactInfo',
        (contactInfo: any, schema: StringSchema) => {
          return contactInfo?.value === 'OTHER'
            ? schema.required('Please specify a contact phone number')
            : schema;
        }
      ),
      contactInfoEmail: Yup.string().when(
        'contactInfo',
        (contactInfo: any, schema: StringSchema) => {
          return contactInfo?.value === 'OTHER'
            ? schema.required('Please specify a contact email')
            : schema;
        }
      ),
    }),

    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      const toastId = toast.loading('Creating shipping...');
      try {
        console.log('creating with values', values);
        await createShipping({
          variables: {
            shipping: {
              name: values.name,
              size: values.size,
              built: values.built,
              type:
                values.type?.value ||
                shippingTypeValues[shippingTypeValues.length - 1].value,

              lastCargoCategoryId: values.lastCargoCategory.value,

              openWindowStart: values.openWindowStart.getTime(),
              openWindowEnd: values.openWindowEnd?.getTime(),

              position: values.position,
              additionalInfo: values.additionalInfo,

              customContactInfo:
                values.contactInfo?.value === 'OTHER'
                  ? {
                      name: values.contactInfoName,
                      companyName: values.contactInfoCompanyName,
                      companyAddress: values.contactInfoCompanyAddress,
                      country: values.contactInfoCountry.value.isoCode,
                      phoneNumber: values.contactInfoPhoneNumber,
                      email: values.contactInfoEmail,
                    }
                  : {
                      name: values.contactInfo?.value.name,
                      companyName: values.contactInfo?.value.companyName,
                      companyAddress: values.contactInfo?.value.companyAddress,
                      country: values.contactInfo?.value.country,
                      phoneNumber: values.contactInfo?.value.phoneNumber,
                      email: values.contactInfo?.value.email,
                    },
            },
            origin: siteUrl,
          },
        });

        toast.success('Shipping position created!', { id: toastId });
        createFormik.resetForm();
        createModal.close();
      } catch (ex) {
        const formError = mapInvalidFieldInvalidFieldNames(
          getInvalidFields(ex),
          new Map([
            ['customContactInfo.phoneNumber', 'contactInfoPhoneNumber'],
            ['customContactInfo.name', 'contactInfoName'],
            ['customContactInfo.email', 'contactInfoEmail'],
            ['customContactInfo.companyName', 'contactInfoCompanyName'],
            ['customContactInfo.companyAddress', 'contactInfoCompanyAddress'],
          ])
        );
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        toast.error(errorMessages?.join('\n') ?? 'Failed to create shipping', {
          id: toastId,
          duration: 10 * 1000,
        });
        if (!errorMessages)
          console.error('[HomePage] createShipping onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const createFormikRef = useRef(createFormik);
  useUpdateRefIfShallowNew(createFormikRef, createFormik);

  useEffect(() => {
    if (createFormik.values.contactInfoCountry) {
      createFormikRef.current.setFieldValue(
        'contactInfoPhoneNumber',
        createFormik.values.contactInfoCountry.value.dialCode
      );
    }
  }, [createFormikRef, createFormik.values.contactInfoCountry]);

  useEffect(() => {
    if (!createFormik.values.contactInfoCountry && userCountry) {
      createFormik.setFieldValue(
        'contactInfoCountry',
        mapCountryToSelectValue(true, userCountry)
      );
    }
  }, [userCountry]);

  return (
    <>
      <Modal
        title="Create Shipping Position"
        contentClassName="ShippingForm-modal"
        control={createModal}
        formProps={{
          onSubmit: createFormik.handleSubmit,
        }}
        size="sm:max-w-3xl sm:w-auto"
        actions={
          <div className="p-4 lg:p-0 w-full bg-background-avg lg:bg-transparent flex justify-between">
            <Button
              className="w-1/3 lg:w-36"
              type="reset"
              onClick={createModal.close}
            >
              Cancel
            </Button>
            <Button
              className="w-2/3 lg:w-36"
              type="submit"
              name="submit"
              variant="primary"
            >
              Create Shipping
            </Button>
          </div>
        }
      >
        <CommonFields formik={createFormik} categoryValues={categoryValues} />

        <hr className="mt-6 bg-white opacity-10" />
        <p className="text-xl mb-0 font-emp font-light">Contact Information</p>

        <div className="flex w-full">
          <SelectInput
            formik={createFormik}
            className="flex-1"
            name="contactInfo"
            values={contactInfoValues}
          />
        </div>

        {createFormik.values.contactInfo?.value === 'OTHER' && (
          <ContactInfoFields formik={createFormik} />
        )}
      </Modal>
    </>
  );
};

export default CreateModal;
