import { getErrorMessages, ModalControl } from '@lib';
import * as React from 'react';
import { FC } from 'react';
import { toast } from 'react-hot-toast';

import { MutationDeleteShippingArgs } from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import Modal from '@components/modal/Modal';

type DeleteModalProps = {
  deleteModal: ModalControl;
  deleteShipping: (args: {
    variables: MutationDeleteShippingArgs;
  }) => Promise<unknown>;
};

const DeleteModal: FC<DeleteModalProps> = ({ deleteModal, deleteShipping }) => {
  async function handleDeleteShipping() {
    const toastId = toast.loading('Deleting shipping...');
    try {
      await deleteShipping({
        variables: {
          shippingId: deleteModal.data.id,
        },
      });

      toast.success('Shipping deleted!', { id: toastId });
      deleteModal.close();
    } catch (ex) {
      const errorMessages = getErrorMessages(ex);
      toast.error(errorMessages?.join('\n') ?? 'Failed to delete shipping', {
        id: toastId,
        duration: 10 * 1000,
      });
      if (!errorMessages) console.error('[HomePage] handleDeleteShipping', ex);
    }
  }

  const title = 'Permanently delete shipping?';
  return (
    <>
      <Modal
        size="max-w-lg w-full mx-4 align-center"
        hideCloseButton
        control={deleteModal}
        actions={
          <div className="flex space-x-6 w-full p-4 lg:p-0">
            <Button
              className="flex-1"
              type="reset"
              onClick={() => {
                deleteModal.close();
              }}
            >
              Cancel
            </Button>
            <>
              <Button
                className="flex-1"
                type="submit"
                name="submit"
                variant="primary"
                onClick={handleDeleteShipping}
              >
                Delete
              </Button>
            </>
          </div>
        }
      >
        <h3
          className="text-base lg:text-3xl font-emp font-bold mb-4 text-left"
          id={`modal-headline-${title}`}
        >
          {title}
        </h3>
        <p className={'text-sm opacity-75 font-emp'}>
          Deleting {deleteModal.data?.name ?? 'this position'} will permanently
          remove it from the shipping table and no one will be able to see it
          anymore.
        </p>
      </Modal>
    </>
  );
};

export default DeleteModal;
