import { FormikControl, useIsMobile, useModal } from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, MutableRefObject, useEffect, useMemo, useState } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';
import * as Yup from 'yup';
import { NumberSchema } from 'yup';

import { HistogramEntry } from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import RangeInput from '@components/form/RangeInput';
import TextInput from '@components/form/TextInput';

import { formatNumber } from '@lib/format';

import DropDownFilter from '../DropDownFilter';

type NumberFilterProps = {
  formik: FormikControl;
  innerFormikRef?: MutableRefObject<FormikControl>;

  label: string;
  unit?: string;

  nameLeft: string;
  nameRight: string;
  histogram: HistogramEntry[];
};

const NumberFilter: FC<NumberFilterProps> = ({
  formik,
  innerFormikRef = { current: undefined },
  label,
  unit,
  nameLeft,
  nameRight,
  histogram,
}) => {
  const dropDownControl = useModal();
  const isMobile = useIsMobile();
  const leftActive = formik.values[nameLeft] !== '';
  const rightActive = formik.values[nameRight] !== '';

  const [lastBounds, setLastBounds] = useState({
    min: Infinity,
    max: -Infinity,
  });
  const min = useMemo(
    () => histogram.reduce((acc, it) => Math.min(acc, it.min), Infinity),
    [histogram]
  );
  const max = useMemo(
    () => histogram.reduce((acc, it) => Math.max(acc, it.max), -Infinity),
    [histogram]
  );

  const tempFormik = useFormik({
    initialValues: {
      left: (formik.values[nameLeft] || '') as number | '',
      right: (formik.values[nameRight] || '') as number | '',
    },
    validationSchema: Yup.object({
      left: Yup.number()
        .min(0, 'Should be positive')
        .when('max', (max: number, schema: NumberSchema) => {
          return schema.max(max ?? Infinity, 'Should be lesser than Max');
        }),
      right: Yup.number().min(0, 'Should be positive'),
    }),
    onSubmit: (values) => {
      formik.setFieldValue(nameLeft, values.left !== min ? values.left : '');
      formik.setFieldValue(nameRight, values.right !== max ? values.right : '');
      dropDownControl.close();
    },
    onReset: () => {
      formik.setFieldValue(nameLeft, '');
      formik.setFieldValue(nameRight, '');
      dropDownControl.close();
    },
  });
  useUpdateRefIfShallowNew(innerFormikRef, tempFormik);

  useEffect(() => {
    if (
      min !== Infinity &&
      (tempFormik.values.left === '' ||
        tempFormik.values.left === lastBounds.min)
    ) {
      tempFormik.setFieldValue('left', min);
    }
    if (
      max != -Infinity &&
      (tempFormik.values.right === '' ||
        tempFormik.values.right === lastBounds.max)
    ) {
      tempFormik.setFieldValue('right', max);
    }
    setLastBounds({ min, max });
  }, [min, max]);

  return (
    <DropDownFilter
      label={
        isMobile || !(leftActive || rightActive)
          ? label
          : leftActive && rightActive
          ? `${formatNumber(formik.values[nameLeft], false)} - ${formatNumber(
              formik.values[nameRight],
              false
            )} ${unit}`
          : leftActive
          ? `> ${formatNumber(formik.values[nameLeft], false)} ${unit}`
          : rightActive
          ? `< ${formatNumber(formik.values[nameRight], false)} ${unit}`
          : '???'
      }
      active={leftActive || rightActive}
      dropDownClassName="w-96"
      dropDownControl={dropDownControl}
    >
      <form
        onSubmit={tempFormik.handleSubmit}
        onReset={tempFormik.handleReset}
        className="mb-0"
      >
        {histogram.length && (
          <RangeInput
            formik={tempFormik}
            startName="left"
            endName="right"
            histogram={histogram}
          />
        )}
        <div className="flex flex-row space-x-14 mb-9 mt-4">
          <TextInput
            type="number"
            name="left"
            formik={tempFormik}
            label="Min"
            endPiece={unit}
            autoFocus={!isMobile}
          />
          <TextInput
            type="number"
            name="right"
            formik={tempFormik}
            label="Max"
            endPiece={unit}
          />
        </div>
        <div className="hidden lg:flex flex-row space-x-14">
          <Button type="reset">Clear</Button>
          <Button type="submit" variant="primary">
            Apply
          </Button>
        </div>
      </form>
    </DropDownFilter>
  );
};

export default NumberFilter;
