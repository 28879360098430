import { ModalControl, SecurityContext } from '@lib';
import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';

import {
  CompleteShippingFragment,
  MutationCreateShippingArgs,
  MutationDeleteShippingArgs,
  MutationUpdateShippingArgs,
} from '@redsleeve/oilynx-domain';

import CreateModal from '@components/ShippingForm/CreateModal';
import DeleteModal from '@components/ShippingForm/DeleteModal';
import UpdateModal from '@components/ShippingForm/UpdateModal';
import { CategoryWithChildren } from '@components/category/domain';
import { SelectInputValue } from '@components/form/SelectInput';

import { useCategories } from '@lib/hooks/useOrderedCategories';

import './ShippingForms.css';

type ShippingFormsProps = {
  contactInfoValues?: SelectInputValue[];
  createShipping?: (args: {
    variables: MutationCreateShippingArgs;
  }) => Promise<unknown>;
  createModal?: ModalControl;
  editShipping: (args: {
    variables: MutationUpdateShippingArgs;
  }) => Promise<unknown>;
  editModal: ModalControl<CompleteShippingFragment>;
  deleteModal: ModalControl;
  deleteShipping: (args: {
    variables: MutationDeleteShippingArgs;
  }) => Promise<unknown>;
};

const ShippingForms: FC<ShippingFormsProps> = ({
  contactInfoValues = [],
  createShipping,
  createModal,
  editShipping,
  editModal,
  deleteShipping,
  deleteModal,
}) => {
  const { groupedCategories } = useCategories();
  const { currentUser } = useContext(SecurityContext);
  const categoryOptions = useMemo(() => {
    function mapCategoryToSelectOption(
      category: CategoryWithChildren
    ): SelectInputValue {
      return {
        value: category.parentId ? category.id : undefined,
        label: category.name,
        options: category.children?.length
          ? category.children.map(mapCategoryToSelectOption)
          : undefined,
        // isDisabled:
        //   !currentUser ||
        //   (currentUser.roles.indexOf(UserRole.SuperAdmin) < 0 &&
        //     currentUser.interests.indexOf(category.parentId) < 0),
      };
    }

    return groupedCategories.map(mapCategoryToSelectOption);
  }, [groupedCategories, currentUser]);

  return (
    <div className="CreateShipping-wrapper">
      {createShipping && createModal && (
        <StaticQuery query={siteUrlQuery}>
          {(data) => (
            <CreateModal
              categoryValues={categoryOptions}
              contactInfoValues={contactInfoValues}
              createModal={createModal}
              createShipping={createShipping}
              siteUrl={data.site.siteMetadata.siteUrl}
            />
          )}
        </StaticQuery>
      )}

      <UpdateModal
        categoryValues={categoryOptions}
        editShipping={editShipping}
        editModal={editModal}
      />

      <DeleteModal deleteModal={deleteModal} deleteShipping={deleteShipping} />
    </div>
  );
};

const siteUrlQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default ShippingForms;
