import { useMutation, useQuery } from '@apollo/client';
import { getQueryName, SecurityContext, useModal } from '@lib';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';

import {
  CreateShippingDocument,
  GetContactInfosDocument,
  GetShippingFilterDataDocument,
  GetShippingsDocument,
  Shipping,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';

import ShippingForm from '../../components/ShippingForm';
import './CreateShipping.css';

type CreateShippingProps = {};

const CreateShipping: FC<CreateShippingProps> = () => {
  const { currentUser, loginControl } = useContext(SecurityContext);
  const userWithContactInfos = useQuery(GetContactInfosDocument);
  const [createShipping] = useMutation(CreateShippingDocument, {
    refetchQueries: [
      getQueryName(GetShippingsDocument),
      getQueryName(GetShippingFilterDataDocument),
    ],
  });
  const createModal = useModal();
  const editModal = useModal<Shipping>();
  const deleteModal = useModal<Shipping>();

  const contactInfoValues = useMemo(
    () => [
      ...(userWithContactInfos.data?.currentUser
        ? userWithContactInfos.data.currentUser.contactInfos.map(
            (contactInfo) => ({
              value: contactInfo,
              label: `${contactInfo.name}, ${contactInfo.email}, ${contactInfo.phoneNumber}, ${contactInfo.companyName}, ${contactInfo.companyAddress}`,
            })
          )
        : []),
      { value: 'OTHER', label: 'Other' },
    ],
    [userWithContactInfos]
  );

  function handleCreateShipping() {
    if (currentUser) createModal.open();
    else loginControl.open();
  }

  return (
    <>
      <div className="CreateShipping-wrapper">
        <Button
          onClick={handleCreateShipping}
          className={`items-center max-w-32
            lg:flex lg:font-bold  
          `}
          variant="primary"
        >
          <svg
            className="h-8 -m-3 lg:m-0 lg:-ml-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          <span className="text-white hidden lg:block">Add Shipping</span>
        </Button>
      </div>

      <ShippingForm
        contactInfoValues={contactInfoValues}
        deleteModal={deleteModal}
        editModal={editModal}
        createModal={createModal}
        createShipping={createShipping}
        editShipping={() => undefined}
        deleteShipping={() => undefined}
      />
    </>
  );
};

export default CreateShipping;
