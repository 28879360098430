import { ModalControl, useCategories } from '@lib';
import clsx from 'clsx';
import * as React from 'react';
import { FC, Fragment } from 'react';

import { CompleteShippingFragment } from '@redsleeve/oilynx-domain';

import { formatDate, formatNumber } from '@lib/format';

type MobileTableProps = {
  shippings: CompleteShippingFragment[];
  viewModal: ModalControl<CompleteShippingFragment>;
};

const MobileTable: FC<MobileTableProps> = ({ shippings, viewModal }) => {
  const { getCategory } = useCategories();

  const rowClass = 'table-row whitespace-nowrap text-xs';
  const headerCellClass = 'table-cell opacity-40 pr-3 py-1 w-32';
  const valueCellClass = 'table-cell truncate py-1';

  return (
    <>
      {shippings.map((shipping, index, self) => (
        <Fragment key={shipping.id}>
          {!shipping.active && (!index || self[index - 1].active) && (
            <div className="flex space-x-4 w-full items-center opacity-60 mt-12 mb-4">
              <hr className="flex-1 bg-interaction-light w-full opacity-60 lg:opacity-100 h-1 mb-0" />
              <div
                className={'font-emp font-bold text-base'}
                style={{ letterSpacing: '4px' }}
              >
                ARCHIVED SHIPPING POSITIONS
              </div>
              <hr className="flex-1 bg-interaction-light w-full opacity-60 lg:opacity-100 h-1 mb-0" />
            </div>
          )}
          <div className={clsx(!shipping.active && 'opacity-70')}>
            <div className="table table-fixed max-w-full w-full relative">
              <div className={rowClass}>
                <div className={headerCellClass}>Name</div>
                <div className={valueCellClass}>{shipping.name}</div>
              </div>

              <div className={rowClass}>
                <div className={headerCellClass}>Size</div>
                <div className={valueCellClass}>
                  {formatNumber(shipping.size)}
                  {' cbm'}
                </div>
              </div>

              <div className={rowClass}>
                <div className={headerCellClass}>Built</div>
                <div className={valueCellClass}>{shipping.built}</div>
              </div>

              <div className={rowClass}>
                <div className={headerCellClass}>Type</div>
                <div className={valueCellClass}>{shipping.type}</div>
              </div>

              <div className={rowClass}>
                <div className={headerCellClass}>Position</div>
                <div className={valueCellClass}>{shipping.position.label}</div>
              </div>

              <div className={rowClass}>
                <div className={headerCellClass}>Open</div>
                <div className={valueCellClass}>
                  {formatDate(shipping.openWindowStart)}
                  {' - '}
                  {formatDate(shipping.openWindowEnd)}
                </div>
              </div>

              <div className={rowClass}>
                <div className={headerCellClass}>Last Cargo</div>
                <div className={valueCellClass}>
                  {getCategory(shipping.lastCargoCategoryId)?.name ?? 'Other'}
                </div>
              </div>

              <button
                className="absolute right-0 top-6 rounded-md bg-background-light"
                onClick={() => viewModal.open(shipping)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 my-2 text-interaction-light"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <hr
              className={clsx(
                'my-3 opacity-10 h-0.5',
                index < self.length - 1 &&
                  self[index + 1].active === shipping.active
                  ? 'bg-white'
                  : 'bg-transparent'
              )}
            />
          </div>
        </Fragment>
      ))}
    </>
  );
};

export default MobileTable;
