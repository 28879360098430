export const shippingTypeValues = [
  { value: 'P | Pressurised', label: 'P | Pressurised' },
  { value: 'S | Semi-Refrigerated', label: 'S | Semi-Refrigerated' },
  { value: 'F | Fully Refrigerated', label: 'F | Fully Refrigerated' },
  { value: 'E | Ethylene', label: 'E | Ethylene' },
  {
    value: 'N/A',
    label: 'N/A',
  },
];
