import { useIsMobile, useModal } from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC } from 'react';

import CheckboxInput from '@components/form/CheckboxInput';

import { incotermValues } from '@data/incotermValues';
import { shippingTypeValues } from '@data/shippingTypeValues';

import DropDownFilter from '../DropDownFilter';

type TypeFilterProps = {
  formik: ReturnType<typeof useFormik>;
  name: string;
};

const TypeFilter: FC<TypeFilterProps> = ({ formik, name }) => {
  const dropDownControl = useModal();
  const isMobile = useIsMobile();
  const activeFilters = formik.values[name].length;

  return (
    <DropDownFilter
      label={`Type${!isMobile && activeFilters ? ` (${activeFilters})` : ''}`}
      active={!!activeFilters}
      dropDownClassName="w-60"
      dropDownControl={dropDownControl}
    >
      <div className="-mt-4">
        {shippingTypeValues.map((shippingType) => (
          <div key={shippingType.value}>
            <CheckboxInput
              className="text-sm"
              formik={formik}
              name={name}
              label={shippingType.label}
              value={shippingType.value}
            />
          </div>
        ))}
      </div>
    </DropDownFilter>
  );
};

export default TypeFilter;
