import {
  FormikControl,
  ShippingsTableSort,
  TradesTableSort,
  useModal,
} from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';

import { HistogramEntry } from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import DeliveryWindowFilter from '@components/filter/DeliveryWindowFilter';
import LastCargoFilter from '@components/filter/shipping/LastCargoFilter';
import NumberFilter from '@components/filter/shipping/NumberFilter';
import TypeFilter from '@components/filter/shipping/TypeFilter';
import RadioInput from '@components/form/RadioInput';
import Modal from '@components/modal/Modal';

type MobileShippingFilterProps = {
  mode: 'map' | 'table';
  filterFormik: FormikControl;
  sizeHistogram: HistogramEntry[];
  builtHistogram: HistogramEntry[];

  sort: string;
  setSort: React.Dispatch<React.SetStateAction<string>>;
};

const MobileShippingFilter: FC<MobileShippingFilterProps> = ({
  mode,
  filterFormik,
  sizeHistogram,
  builtHistogram,
  sort,
  setSort,
}) => {
  const sortModalControl = useModal();
  const filterModalControl = useModal();

  const openWindowFormikRef = useRef<FormikControl>();
  const sizeFormikRef = useRef<FormikControl>();
  const builtFormikRef = useRef<FormikControl>();

  const numberActive = [
    filterFormik.values['categories'].length,
    filterFormik.values['types'].length,

    filterFormik.values['minSize'] !== '' ||
      filterFormik.values['maxSize'] !== '',

    filterFormik.values['minBuilt'] !== '' ||
      filterFormik.values['maxBuilt'] !== '',

    !!filterFormik.values['openWindowStart'] ||
      !!filterFormik.values['openWindowEnd'],
  ].filter((it) => !!it).length;

  async function onClear() {
    filterFormik.setFieldValue('categories', []);
    await openWindowFormikRef.current?.resetForm();
    await sizeFormikRef.current?.resetForm();
    await builtFormikRef.current?.resetForm();

    filterModalControl.close();
  }

  async function onApply() {
    await openWindowFormikRef.current?.submitForm();
    await sizeFormikRef.current?.submitForm();
    await builtFormikRef.current?.submitForm();

    filterModalControl.close();
  }

  const sortFormik = useFormik({
    initialValues: {
      sort: '-_id',
    },
    onSubmit: (values) => {
      setSort(values.sort);
      sortModalControl.close();
    },
    onReset: () => {
      setSort(sortFormik.initialValues.sort);
      sortModalControl.close();
    },
  });

  useEffect(() => {
    if (!sortModalControl.isOpen) {
      sortFormik.setFieldValue('sort', sort);
    }
  }, [sortModalControl.isOpen]);

  function renderButtons() {
    if (mode === 'table') {
      return (
        <div className="flex lg:hidden space-x-3 mt-5 mb-5">
          <Button
            variant="outlined-light"
            className="flex-1 p-1 flex items-center"
            onClick={() => filterModalControl.open()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 mr-2 text-interaction-light"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
            </svg>
            Filter
          </Button>
          <Button
            variant="outlined-light"
            className="flex-1 p-1 flex items-center"
            onClick={() => sortModalControl.open()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 mr-2 text-interaction-light"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
            </svg>
            Sort
          </Button>
        </div>
      );
    } else {
      return (
        <Button
          variant="outlined-light"
          className="lg:hidden w-8 h-8 fixed right-0 top-10 z-10 transform -translate-x-3 translate-y-3"
          onClick={() => filterModalControl.open()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 -m-2 -mt-1.5 text-interaction-light"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
          </svg>
          {!!numberActive && (
            <div
              className={`absolute bottom-0 left-0 
              transform -translate-x-1/2 translate-y-1/2 
              rounded-full bg-interaction-light text-white text-xs
              w-4 h-4 text-center
            `}
            >
              {numberActive}
            </div>
          )}
        </Button>
      );
    }
  }

  return (
    <>
      {renderButtons()}

      <Modal
        title="Sort"
        contentClassName="h-screen"
        childrenWrapperClassName="flex flex-col"
        control={sortModalControl}
        formProps={{
          onSubmit: sortFormik.handleSubmit,
          onReset: sortFormik.handleReset,
        }}
      >
        <RadioInput
          className="text-sm"
          formik={sortFormik}
          name="sort"
          label="Name A-Z"
          value={ShippingsTableSort.NameASC}
        />
        <RadioInput
          className="text-sm"
          formik={sortFormik}
          name="sort"
          label="Name Z-A"
          value={ShippingsTableSort.NameDESC}
        />

        <RadioInput
          className="text-sm mt-4"
          formik={sortFormik}
          name="sort"
          label="Type A-Z"
          value={ShippingsTableSort.TypeASC}
        />
        <RadioInput
          className="text-sm"
          formik={sortFormik}
          name="sort"
          label="Type Z-A"
          value={ShippingsTableSort.TypeDESC}
        />

        <RadioInput
          className="text-sm mt-4"
          formik={sortFormik}
          name="sort"
          label="Last Cargo A-Z"
          value={ShippingsTableSort.LastCargoASC}
        />
        <RadioInput
          className="text-sm"
          formik={sortFormik}
          name="sort"
          label="Last Cargo Z-A"
          value={ShippingsTableSort.LastCargoDESC}
        />

        <RadioInput
          className="text-sm  mt-4"
          formik={sortFormik}
          name="sort"
          label="Size ascending"
          value={ShippingsTableSort.SizeASC}
        />
        <RadioInput
          className="text-sm"
          formik={sortFormik}
          name="sort"
          label="Size descending"
          value={ShippingsTableSort.SizeDESC}
        />

        <RadioInput
          className="text-sm  mt-4"
          formik={sortFormik}
          name="sort"
          label="Built ascending"
          value={ShippingsTableSort.BuiltASC}
        />
        <RadioInput
          className="text-sm"
          formik={sortFormik}
          name="sort"
          label="Built descending"
          value={ShippingsTableSort.BuiltDESC}
        />

        <RadioInput
          className="text-sm mt-4"
          formik={sortFormik}
          name="sort"
          label="Open Window ascending"
          value={ShippingsTableSort.OpenWindowASC}
        />
        <RadioInput
          className="text-sm"
          formik={sortFormik}
          name="sort"
          label="Open Window descending"
          value={ShippingsTableSort.OpenWindowDESC}
        />

        <div className="flex-1" />

        <div className="bg-background-avg flex flex-row space-x-6 -m-4 p-4">
          <Button className="p-2 w-1/3" type="reset">
            Reset
          </Button>
          <Button className="p-2 w-2/3" type="submit" variant="primary">
            Apply
          </Button>
        </div>
      </Modal>

      <Modal title="Filters" control={filterModalControl}>
        <LastCargoFilter formik={filterFormik} name="categories" />
        <hr className="my-4 bg-white opacity-10 h-0.5" />
        <NumberFilter
          formik={filterFormik}
          innerFormikRef={sizeFormikRef}
          nameLeft="minSize"
          nameRight="maxSize"
          label="Size"
          unit="cbm"
          histogram={sizeHistogram}
        />
        <hr className="my-4 bg-white opacity-10 h-0.5" />
        <NumberFilter
          formik={filterFormik}
          innerFormikRef={builtFormikRef}
          nameLeft="minBuilt"
          nameRight="maxBuilt"
          label="Built"
          unit=""
          histogram={builtHistogram}
        />
        <hr className="my-4 bg-white opacity-10 h-0.5" />
        <TypeFilter formik={filterFormik} name="types" />
        <hr className="my-4 bg-white opacity-10 h-0.5" />
        <DeliveryWindowFilter
          formik={filterFormik}
          innerFormikRef={openWindowFormikRef}
          label="Open Window"
          nameStart="openWindowStart"
          nameEnd="openWindowEnd"
        />

        <div className="bg-background-avg flex flex-row space-x-6 -m-4 p-4 mt-0">
          <Button className="p-2 w-1/3" type="reset" onClick={onClear}>
            Clear All
          </Button>
          <Button
            className="p-2 w-2/3"
            type="submit"
            variant="primary"
            onClick={onApply}
          >
            Apply
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MobileShippingFilter;
